import React, { useEffect, useState } from 'react';

import { Button } from 'primereact/button';
import { COUNT_PER_PAGE } from '../../../constants/Constants';
import { Column } from 'primereact/column';
import { CommonUtil } from '../../../utils/commonUtil';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { MyInfoUtil } from '../../../utils/myInfoUtil';
import { ServiceProvider } from '../../../services';
import _ from 'lodash';

//* 전체 데이터 조회하게 되는 query
const DEFAULT_SEARCH_CONDITIONS = {
  associations: [],
  enterprises: [],
  shops: [],
  searchText: '',
  useYN: 'Y',
};
const DEFAULT_LAZY_PARAMS = {
  first: 0,
  rows: 10,
  page: 0,
  sortField: null,
  sortOrder: null,
  filters: {
    name: { value: '', matchMode: 'contains' },
    'country.name': { value: '', matchMode: 'contains' },
    company: { value: '', matchMode: 'contains' },
    'representative.name': { value: '', matchMode: 'contains' },
  },
};

const customerCompanyService = ServiceProvider.customerCompany;

export const CustomerCompanyDialog = ({
  preSelectedAssociationId,
  preSelectedEnterpriseId,
  preSelectedShopId,
  searchText,
  onHide,
  userInfo,
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);

  const [searchConditions, setSearchConditions] = useState({
    ...DEFAULT_SEARCH_CONDITIONS,
  });
  const [lazyParams, setLazyParams] = useState({ ...DEFAULT_LAZY_PARAMS });

  async function getList(conditons, page = 0, size = 10) {
    setLoading(true);

    const params = {
      ...conditons,
      page: page + 1,
      size: size,
    };

    try {
      const { data } = await customerCompanyService.list(params);

      setData(data.list);
      setTotalRecords(data.total);
    } catch (error) {
      console.log(error);
      window.cerp.dialog.error(
        '매매상사 조회 실패',
        `[${error?.code}]${error?.message}`
      );
    }

    setLoading(false);
  }

  useEffect(() => {
    (async () => {
      const { myAssociationId, myEnterpriseId, myShopId } =
        MyInfoUtil.unpack(userInfo);

      const updatedSearchConditions = {
        ...searchConditions,
        searchText: searchText,
        associations: preSelectedAssociationId
          ? [preSelectedAssociationId]
          : [myAssociationId],
        enterprises: preSelectedEnterpriseId
          ? [preSelectedEnterpriseId]
          : [myEnterpriseId],
        shops: preSelectedShopId ? [preSelectedShopId] : [myShopId],
      };

      setSearchConditions((ps) => ({
        ...ps,
        ...updatedSearchConditions,
      }));

      await getList(updatedSearchConditions);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  return (
    <Dialog
      visible
      modal
      blockScroll
      header="매매상사 검색"
      style={{ width: '72vw' }}
      breakpoints={{ '960px': '84vw', '640px': '96vw' }}
      onHide={onHide}
    >
      <div className="grid">
        <div className="col-12">
          <label htmlFor="searchText"></label>
          <div className="p-inputgroup">
            <InputText
              value={searchConditions.searchText}
              placeholder="매매상사 이름을 입력하세요."
              onChange={(e) => {
                setSearchConditions((ps) => ({
                  ...ps,
                  searchText: e.target.value,
                }));
              }}
              onKeyDown={async (e) => {
                if (e.key === 'Enter') {
                  await getList(
                    {
                      ...searchConditions,
                      searchText: e.target.value,
                    },
                    _.get(DEFAULT_LAZY_PARAMS, 'page'),
                    _.get(DEFAULT_LAZY_PARAMS, 'rows')
                  );
                }
              }}
            />
            <Button
              type="button"
              icon="pi pi-search"
              onClick={async () =>
                await getList(
                  searchConditions,
                  _.get(DEFAULT_LAZY_PARAMS, 'page'),
                  _.get(DEFAULT_LAZY_PARAMS, 'rows')
                )
              }
            />
          </div>
        </div>
      </div>
      <DataTable
        size="small"
        loading={loading}
        value={data}
        lazy
        rows={lazyParams.rows}
        first={lazyParams.first}
        totalRecords={totalRecords}
        paginator
        responsiveLayout="scroll"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="전체 {totalRecords}건 중 {first} ~ {last}"
        rowsPerPageOptions={COUNT_PER_PAGE}
        onPage={async (event) => {
          setLazyParams(event);
          await getList(searchConditions, event.page, event.rows);
        }}
        resizableColumns
        showGridlines
        selectionPageOnly={true}
        emptyMessage={
          <div className="flex flex-column align-items-center my-3">
            <p>등록된 매매상사가 없습니다.</p>
            <p>계정 정보를 직접 입력해주세요.</p>
          </div>
        }
        rowHover
        rowClassName="cursor-pointer"
        onRowClick={({ data }) => onHide(data)}
      >
        <Column field="companyName" header="매매상사명" />
        <Column
          className="text-center"
          field="representative"
          header="대표자명"
        />
        <Column
          field="mainPhone"
          header="대표전화"
          body={({ mainPhone }) => (
            <span>{mainPhone && CommonUtil.Formatter.phone(mainPhone)}</span>
          )}
        />
        <Column
          field="address"
          header="주소"
          body={({ address, addressDetail }) => (
            <span>{address + addressDetail}</span>
          )}
        />
      </DataTable>
    </Dialog>
  );
};
