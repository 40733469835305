import React, { forwardRef } from 'react';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { classNames } from 'primereact/utils';
import { ADD_PARTS_STATUS } from '../../../constants/PerformanceCheckConstants';

const AddPartRecordMenu = forwardRef((props, ref) => {
  const { id, index, partItem, menuRef, selected, onChange } = props;

  const title = `${partItem.partNumber}.${partItem.title}`;

  // 메뉴 아이템 동적 생성
  const statusItems = ADD_PARTS_STATUS.filter(
    (item) => item.category === partItem.category
  ) // partItem의 category에 맞는 항목 필터링
    .map((item) => ({
      label: item.status_name,
      value: item.status_code,
      severity: item.severity,
      command: () => onChange(item.status_code), // 상태 변경 시 onChange 호출
      template: MenuItem, // 사용자 정의 템플릿
    }));

  const items = [
    {
      label: title,
      template: (item, options) => (
        <div className="flex flex-auto align-items-center justify-content-center py-1">
          <span
            className="font-bold text-center"
            style={{ wordBreak: 'keep-all' }}
          >
            {item.label}
          </span>
        </div>
      ),
    },
    {
      separator: true,
    },
    ...statusItems, // 동적 생성된 상태 아이템 추가
    {
      separator: true,
    },
    {
      label: '선택 취소',
      value: null,
      command: () => onChange('1'),
      template: (item, options) => (
        <div
          className={classNames(options.className, 'p-1')}
          onClick={options.onClick}
        >
          <div className="flex flex-auto align-items-center justify-content-start gap-2 p-2">
            <span className="pi pi-ban" />
            <span className={options.labelClassName}>{item.label}</span>
          </div>
        </div>
      ),
    },
  ];

  // 선택된 상태의 status_code 찾기
  const selectedStatus = ADD_PARTS_STATUS.find(
    (item) => item.status_code === selected
  );

  return (
    <div ref={ref}>
      <Menu
        model={items}
        popup
        ref={(el) => (menuRef.current[index] = el)}
        id={id}
      />
      <Button
        type="button"
        className={classNames(
          `p-0 font-bold border-900 p-button-raised p-button-rounded`,
          {
            'bg-white': !selectedStatus,
            'p-button-secondary text-white': ['T'].includes(
              selectedStatus?.status_code
            ),
            'p-button-contrast text-white': ['G'].includes(
              selectedStatus?.status_code
            ),
            'p-button-success': ['R'].includes(selectedStatus?.status_code),
            'p-button-warning': ['E'].includes(selectedStatus?.status_code),
            'p-button-danger': ['X'].includes(selectedStatus?.status_code),
          }
        )}
        style={{
          width: 20,
          height: 20,
          position: 'absolute',
          top: partItem.pos[0],
          left: partItem.pos[1],
          zIndex: 99,
        }}
        label={selectedStatus ? selectedStatus.status_code : ''}
        onClick={(e) => {
          menuRef.current[index].toggle(e);
        }}
        aria-controls={id}
        aria-haspopup
        tooltip={title}
      />
    </div>
  );
});

const MenuItem = (item, options) => {
  return (
    <div
      className={classNames(
        options.className,
        'flex flex-auto align-items-center justify-content-start gap-2 p-2'
      )}
      onClick={options.onClick}
    >
      <Badge value={item.value} severity={item.severity} />
      <span className={options.labelClassName}>{item.label}</span>
    </div>
  );
};

export default AddPartRecordMenu;
