import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { PC_ADD_BLUEPRINT } from '../../constants/PerformanceCheckConstants';
import { Controller } from 'react-hook-form';
import { Checkbox } from 'primereact/checkbox';
import { InputNumber } from 'primereact/inputnumber';

const addSuffix = _.mapValues(PC_ADD_BLUEPRINT, 'suffix');
const addValueItems = _.mapValues(PC_ADD_BLUEPRINT, 'valueItems');
// wheelPositions를 함수로 변경하여 동적으로 위치 계산
const getWheelPositions = (isMobile) => [
  { position: 'FL', top: 40, left: isMobile ? -50 : -60 },
  { position: 'RL', top: 250, left: isMobile ? -50 : -60 },
  { position: 'FR', top: 40, left: 180 },
  { position: 'RR', top: 250, left: 180 },
];

export const WheelGroups = ({
  control,
  positions = ['FL', 'FR', 'RL', 'RR'],
}) => {
  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    // 초기 상태 설정
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 640); // Tailwind의 sm breakpoint는 640px
    };

    // 초기 실행
    checkMobile();

    // resize 이벤트 리스너 등록
    window.addEventListener('resize', checkMobile);

    // cleanup
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // 현재 화면 크기에 맞는 위치 계산
  const currentWheelPositions = getWheelPositions(isMobile);

  // positions prop에 포함된 위치의 바퀴만 필터링
  const filteredWheels = currentWheelPositions.filter((wheel) =>
    positions.includes(wheel.position)
  );

  return (
    <>
      {filteredWheels.map((wheel) => (
        <div
          key={wheel.position}
          className="w-5"
          style={{
            position: 'absolute',
            top: wheel.top,
            left: wheel.left,
            zIndex: 99,
          }}
        >
          {/* Wheel Input */}
          <Controller
            control={control}
            name={`carWheel${wheel.position}`}
            render={({ field }) => (
              <div className="p-inputgroup">
                <span className="p-inputgroup-addon">W</span>
                <div className="p-inputgroup-addon bg-white w-full gap-2 flex-column">
                  {addValueItems[field.name].map((item) => (
                    <div
                      key={`${field.name}_${item.value}_div`}
                      className="flex align-items-center gap-2"
                    >
                      <Checkbox
                        inputId={`${field.name}_${item.value}`}
                        checked={field.value === item.value}
                        onChange={(e) => {
                          field.onChange(e.checked ? item.value : '');
                        }}
                      />
                      <label htmlFor={`${field.name}_${item.value}`}>
                        {item.label}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            )}
          />

          {/* Tire Tread Input */}
          <Controller
            control={control}
            name={`tiretread${wheel.position}`}
            render={({ field }) => (
              <div className="flex">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">T</span>
                  <InputNumber
                    value={field.value}
                    onFocus={(e) => e.target.select()}
                    onChange={(e) => field.onChange(e.value)}
                    suffix={
                      addSuffix[field.name] ? ` ${addSuffix[field.name]}` : ''
                    }
                    placeholder={addSuffix[field.name]}
                    inputClassName="text-right"
                    className="w-full"
                    minFractionDigits={1}
                    maxFractionDigits={1}
                    min={0}
                    max={100}
                  />
                </div>
              </div>
            )}
          />

          {/* Brake Pad Input */}
          <Controller
            control={control}
            name={`brakePad${wheel.position}`}
            render={({ field }) => (
              <div className="flex">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">B</span>
                  <InputNumber
                    value={field.value}
                    onFocus={(e) => e.target.select()}
                    onChange={(e) => field.onChange(e.value)}
                    suffix={
                      addSuffix[field.name] ? ` ${addSuffix[field.name]}` : ''
                    }
                    placeholder={addSuffix[field.name]}
                    inputClassName="text-right"
                    className="w-full"
                    minFractionDigits={1}
                    maxFractionDigits={1}
                    min={0}
                    max={100}
                  />
                </div>
              </div>
            )}
          />
        </div>
      ))}
    </>
  );
};
