import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import * as Modal from './../../components/Atoms/Modal';
import * as Button from './../../components/Atoms/Button';
import Counseling from '../../components/Accident/Counseling';
import CustomerDocument from '../../components/Accident/CustomerDocument';
import PartnerDocument from '../../components/Accident/PartnerDocument';
import Repair from '../../components/Accident/Repair';
import WarrantyDiagnosis from '../../components/Accident/WarrantyDiagnosis';
import Claim from '../../services/ClaimService';
import { Panel } from 'primereact/panel';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { CommonUtil } from '../../utils/commonUtil';
import { BlockUI } from 'primereact/blockui';
import { Dropdown } from 'primereact/dropdown';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import { useQuery } from 'react-query';
import { Divider } from 'primereact/divider';
import { Calendar } from 'primereact/calendar';
import { useRecoilValueLoadable } from 'recoil';
import { myInfoSelector } from '../../recoil/selectors';
import { ServiceProvider } from '../../services/index';
import dayjs from 'dayjs';
import SendSMS from './SendSMS';
import { Partner } from '../../services/PartnerService';

const kakaoService = ServiceProvider.kakao;

function ClaimDialog() {
  const history = useHistory();
  const myInfo = useRecoilValueLoadable(myInfoSelector);
  const partner = myInfo.contents.roleCode === 'D_ADM';

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customerInfo, setCustomerInfo] = useState({}); // 신규 등록
  const [detailData, setDetailData] = useState({}); // claimData 기존고객
  const [docs, setDocs] = useState([]); // claimDocs
  const [carDetailData, setCarDetailData] = useState(null); // 자동자 상세정보
  const [carAddress, setCarAddress] = useState(''); // 차량 위치
  const [warrantyData, setWarrantyData] = useState({
    buyDate: '',
    checkDistance: 0,
    distanceDriven: 0,
    diagnosisType: [],
    reservationDate: '',
    producingCountry: '',
    carName: '',
    partnerId: '', //opt
    radius: 0,
    partnerNote: '', // opt
  });

  const [carYX, setCarYX] = useState('');
  const getAddressInfo = async (address) => {
    const {
      documents: [res],
    } = await kakaoService.getAddressInfo({
      query: address,
    });
    // console.log(res);
    // console.log('좌표 정보', '경도', res.x, '위도', res.y);
    setCarYX(`${res.y},${res.x}`);
  };

  const [partnerList, setPartnerList] = useState([]); // 진단점 리스트
  const getPartnerList = async (location, radius) => {
    try {
      const data = await Partner.nearList({
        page: 1,
        size: 99,
        carRadius: Number(radius),
        carLocation: location,
      });
      // console.log(data);
      if (data) {
        setPartnerList(data.data.list);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (carYX && warrantyData.radius) {
      getPartnerList(carYX, warrantyData.radius);
    }
  }, [carYX, warrantyData.radius]);

  //TODO - 이미지 삭제, 다시 올리는 경우 수정 필요
  const [fileItems, setFileItems] = useState([]); // 모든 사진 파일
  const [deleteImgs, setDeleteImgs] = useState([]); // 모든 삭제하는 사진 파일
  const [copyFile, setCopyFile] = useState([]); // 사진 파일 카피
  const [statusCode, setStatusCode] = useState('');
  const [repairData, setRepairData] = useState({
    date: '',
    maintenanceCode: '',
    workYN: '',
    workFee: null, // int
  }); // 수리정보

  const today = new Date();

  const [insuranceInfo, setInsuranceInfo] = useState({
    payDate: '',
    paymentAmt: null, // int
    deductibleAmt: null, // int
    completeDate: '',
    insurancePolicyNumber: '', // 증권번호
  });

  const { data: status } = useQuery({
    queryKey: ['status'],
    queryFn: () => Claim.getDocsType('Z'),
  });
  const statusList = useMemo(() => status?.data || [], [status]);

  const { data: insurance } = useQuery({
    queryKey: ['insurance'],
    queryFn: () => Claim.getInsurances(),
  });
  const insuranceList = useMemo(() => insurance || [], [insurance]);

  useEffect(() => {
    if (history.location.state) {
      setCustomerInfo(history.location.state.customerInfo);
    }
  }, [history.location.state]);

  const getDetailData = async (dataId) => {
    setLoading(true);
    try {
      const data = await Claim.getDetail(dataId);
      if (data) {
        // console.log(data);
        if (data.claimData.carLocation) {
          setCarYX(data.claimData.carLocation);
        } else if (data.claimData.carAddress) {
          getAddressInfo(data.claimData.carAddress);
        }
        if (data.claimData.carAddress) {
          setCarAddress(data.claimData.carAddress);
        }
        setDetailData(data.claimData);
        setDocs(data.claimDocuments);
        setWarrantyData({
          buyDate: data.claimData.buyDate,
          checkDistance: data.claimData.checkDistance,
          distanceDriven: data.claimData.distanceDriven,
          diagnosisType: data.claimData.diagnosisType,
          reservationDate: data.claimData.reservationDate,
          producingCountry: data.claimData.producingCountry,
          carName: data.claimData.carName,
          partnerId: data.claimData.partnerId,
          partnerNote: data.claimData.partnerNote,
        });
        setStatusCode(data.claimData.claimStatus);
        setRepairData({
          date: data.claimData.partnerReceiptDate
            ? CommonUtil.Formatter.stringToDate(
                data.claimData.partnerReceiptDate
              )
            : '',
          maintenanceCode: data.claimData.maintenanceCode
            ? data.claimData.maintenanceCode.split(',')
            : '',
          workYN: data.claimData.workYN,
          workFee: data.claimData.workFee,
        });
        setInsuranceInfo({
          payDate: data.claimData.insurancePurchaseDate
            ? CommonUtil.Formatter.stringToDate(
                data.claimData.insurancePurchaseDate
              )
            : '',
          paymentAmt: data.claimData.insurancePaymentAmt, // int
          deductibleAmt: data.claimData.deductibleAmt, // int
          completeDate: data.claimData.completeDate
            ? CommonUtil.Formatter.stringToDate(data.claimData.completeDate)
            : '',
          insurancePolicyNumber: data.claimData.insurancePolicyNumber
            ? data.claimData.insurancePolicyNumber
            : '',
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (docs) {
      setFileItems(docs);
      setCopyFile(docs);
    }
  }, [docs]);

  //TODO - 클레임 등록 POST
  const registerClaim = async () => {
    setLoading(true);
    const fileData = [];
    fileItems.map((el, idx) =>
      fileData.push({
        documentCode: el.documentCode,
        state: el.state,
      })
    );
    const files = [];
    fileItems.map((el) => files.push(el.file));

    const Data = {
      funnelsType: customerInfo.funnelsType ? customerInfo.funnelsType : 'W', // W or A
      claimStatus: statusCode,
      customerName: customerInfo.customerName,
      customerContact: customerInfo.customerContact,
      statementNumber: carDetailData.performanceCheckData.statementNumber,
      carNbr: carDetailData.performanceCheckData.VHRNO,
      checkDate: carDetailData.performanceCheckData.checkDate,
      buyDate: warrantyData.buyDate
        ? CommonUtil.Formatter.dateToString(warrantyData.buyDate)
        : '',
      checkDistance: warrantyData.checkDistance, //vehicleInformation > TRVL_DSTNC
      distanceDriven: warrantyData.distanceDriven
        ? Number(warrantyData.distanceDriven)
        : null,
      producingCountry: warrantyData.producingCountry, // D or F
      carName: warrantyData.carName,
      carAddress: carAddress ? carAddress : null,
      carLocation: carYX ? carYX : null, //Opt
      diagnosisType:
        warrantyData.diagnosisType &&
        typeof warrantyData.diagnosisType === 'string'
          ? warrantyData.diagnosisType
          : warrantyData.diagnosisType && warrantyData.diagnosisType.length > 0
          ? warrantyData.diagnosisType.join()
          : null, // 진단타입 B, D..
      consultationGroupId: null,
      reservationDate: !!warrantyData.reservationDate
        ? CommonUtil.Formatter.dateToString(warrantyData.reservationDate)
        : null, //Opt
      partnerId: warrantyData.partnerId ? warrantyData.partnerId : null, //Opt
      receiptId: myInfo.contents.userId ? myInfo.contents.userId : null,
      receiptDate: CommonUtil.Formatter.dateToString(today), // 접수 일자 -> 오늘날짜 넣기
      documentGroupId: null, //Opt
      note: null, //Opt
      partenerReceiptDate: repairData.date
        ? CommonUtil.Formatter.dateToString(repairData.date)
        : null, //Opt 수리 입고일자
      maintenanceCode: !!repairData.maintenanceCode.length
        ? repairData.maintenanceCode.join()
        : null, //Opt // 수리 부위 - 진단코드
      workYN: repairData.workYN ? repairData.workYN : null, //Opt 수리여부
      workFee: repairData.workFee ? Number(repairData.workFee) : null, //Opt 협의비용
      insuranceCode: paymentInfo.ICNY_CODE, // opt 보험코드
      insurancePolicyNumber: insuranceInfo.insurancePolicyNumber
        ? insuranceInfo.insurancePolicyNumber
        : null, //opt 증권번호
      insurancePurchaseDate: insuranceInfo.payDate
        ? CommonUtil.Formatter.dateToString(insuranceInfo.payDate)
        : null, //Opt 보험료 지급일
      insurancePaymentAmt: insuranceInfo.paymentAmt
        ? insuranceInfo.paymentAmt
        : null, //Opt 보험료
      deductibleAmt: insuranceInfo.deductibleAmt
        ? insuranceInfo.deductibleAmt
        : null, // opt 자기부담금
      partnerNote: warrantyData.partnerNote ? warrantyData.partnerNote : null, //Opt
      completeYN: insuranceInfo.completeDate ? 'Y' : 'N', //Opt 완료여부
      completeDate: insuranceInfo.completeDate
        ? CommonUtil.Formatter.dateToString(insuranceInfo.completeDate)
        : null, //Opt 완료일
      completeLoginId: myInfo.contents.loginId, //Opt 로그인유저 아이디
      completeLoginContact: myInfo.contents.contact, //opt 로그인유저 연락처
      fileData: fileData, //Otp
    };
    // console.log(Data);
    const checkData =
      !!Data.funnelsType &&
      // !!Data.claimStatus &&
      !!Data.customerName &&
      !!Data.customerContact &&
      !!Data.statementNumber &&
      !!Data.carNbr &&
      !!Data.checkDate &&
      !!Data.checkDistance &&
      !!Data.producingCountry &&
      !!Data.carName &&
      // !!Data.receiptId &&
      !!Data.receiptDate;

    if (!checkData) {
      setLoading(false);
      window.cerp.toast.warn('필수 입력값을 입력해주세요.');
      return;
    }
    try {
      const data = await Claim.registerClaim(Data, files);
      if (data) {
        window.cerp.toast.info('등록이 완료 되었습니다.');
        history.push('/compensation/accident/list');
        sessionStorage.removeItem('images');
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      window.cerp.toast.error(error?.message);
      setLoading(false);
    }
  };

  const paymentInfo = carDetailData
    ? JSON.parse(carDetailData?.performanceCheckData?.paymentInformation)
    : '';

  //TODO - 클레임 수정 PUT
  const changetClaim = async () => {
    setLoading(true);

    let fileDataList = [];
    let filesList = [];

    const del = [];
    if (!!deleteImgs.length) {
      // console.log('삭제하려는 이미지가 있는 경우 => 기존 이미지');
      deleteImgs.map((el) =>
        del.push({ imgId: el.imgId, documentCode: el.documentCode, state: 'D' })
      );
      // console.log(del);
      fileDataList = del;
    }
    const findFile = fileItems.find((el) => el.file); // File 이 있는지 찾는다.
    const findImgId = fileItems.find((el) => el.imgId); // 기존 이미지를 찾는다.
    const idFile = []; // imgId 를 가지고 있는 기존 이미지파일
    fileItems.filter((el) => el.imgId && idFile.push(el));
    const imgFile = []; // file 을 가지고 있는 새로 등록한 이미지 파일
    fileItems.filter((el) => el.file && imgFile.push(el));

    if (findFile && !findImgId) {
      // console.log('새 이미지');
      fileItems.map((el) =>
        fileDataList.push({
          documentCode: el.documentCode,
          state: el.state,
        })
      );
      fileItems.map((el) => filesList.push(el.file));
    } else if (!findFile && findImgId) {
      // console.log('파일이 없고, 아이디만 있다 => 기존 이미지');
    } else if (findFile && findImgId) {
      // console.log('파일이 있고, 아이디도 있다 => 새 이미지 + 기존 이미지');

      imgFile.map((el, idx) =>
        fileDataList.push({
          documentCode: el.documentCode,
          state: el.state,
        })
      );
      imgFile.map((el) => filesList.push(el.file));
    }

    const updateData = {
      funnelsType: customerInfo.funnelsType, // W or A
      claimStatus: statusCode,
      customerName: customerInfo.customerName,
      customerContact: customerInfo.customerContact,
      statementNumber: carDetailData.performanceCheckData.statementNumber,
      carNbr: carDetailData.performanceCheckData.VHRNO,
      checkDate: carDetailData.performanceCheckData.checkDate,
      buyDate:
        typeof warrantyData.buyDate !== 'string'
          ? CommonUtil.Formatter.dateToString(warrantyData.buyDate)
          : detailData?.buyDate,
      checkDistance: warrantyData.checkDistance, //vehicleInformation > TRVL_DSTNC
      distanceDriven: warrantyData.distanceDriven,
      producingCountry: warrantyData.producingCountry, // D or F
      carName: warrantyData.carName,
      carAddress: carAddress ? carAddress : null,
      carLocation: carYX ? carYX : null, //Opt
      diagnosisType:
        warrantyData.diagnosisType &&
        typeof warrantyData.diagnosisType === 'string'
          ? warrantyData.diagnosisType
          : warrantyData.diagnosisType && warrantyData.diagnosisType.length > 0
          ? warrantyData.diagnosisType.join()
          : null, // 진단타입 B, D..
      consultationGroupId: detailData?.consultationGroupId,
      reservationDate:
        typeof warrantyData.reservationDate === 'string'
          ? warrantyData.reservationDate
          : warrantyData.reservationDate
          ? CommonUtil.Formatter.dateToString(warrantyData.reservationDate)
          : null, //Opt
      partnerId: warrantyData.partnerId
        ? warrantyData.partnerId.toString()
        : null,
      receiptId: myInfo.contents.userId ? myInfo.contents.userId : null,
      receiptDate: detailData?.receiptDate, // 접수 일자
      documentGroupId: detailData?.documentGroupId, //Opt
      note: null, //Opt
      partenerReceiptDate:
        typeof repairData.date !== 'string'
          ? CommonUtil.Formatter.dateToString(repairData.date)
          : detailData?.partenerReceiptDate, //Opt 수리 입고일자
      maintenanceCode: detailData?.maintenanceCode
        ? detailData?.maintenanceCode
        : repairData.maintenanceCode.length > 0
        ? repairData.maintenanceCode.join()
        : null,
      workYN: repairData.workYN ? repairData.workYN : null, //Opt 수리여부
      workFee: repairData.workFee ? Number(repairData.workFee) : null, //Opt 협의비용
      insuranceCode: paymentInfo.ICNY_CODE, // opt 보험코드
      insurancePolicyNumber: insuranceInfo.insurancePolicyNumber
        ? insuranceInfo.insurancePolicyNumber
        : null, //opt 증권번호
      insurancePurchaseDate: insuranceInfo.payDate
        ? CommonUtil.Formatter.dateToString(insuranceInfo.payDate)
        : null, //Opt 보험료 지급일
      insurancePaymentAmt: insuranceInfo.paymentAmt
        ? insuranceInfo.paymentAmt
        : null, //Opt 보험료
      deductibleAmt: insuranceInfo.deductibleAmt
        ? insuranceInfo.deductibleAmt
        : null, // opt 자기부담금
      partnerNote: warrantyData.partnerNote ? warrantyData.partnerNote : null, //Opt
      completeYN: insuranceInfo.completeDate ? 'Y' : 'N', //Opt 완료여부
      completeDate: insuranceInfo.completeDate
        ? CommonUtil.Formatter.dateToString(insuranceInfo.completeDate)
        : null, //Opt 완료일
      completeLoginId: myInfo.contents.loginId, //Opt 로그인유저 아이디
      completeLoginContact: myInfo.contents.contact, //opt 로그인유저 연락처
      fileData: fileDataList, //Otp
    };
    console.log('data =>', updateData);
    // console.log('files =>', filesList);

    const checkData =
      !!updateData.checkDate &&
      // !!updateData.claimStatus &&
      !!updateData.checkDistance &&
      !!updateData.producingCountry &&
      !!updateData.carName &&
      // !!updateData.diagnosisType &&
      // !!updateData.receiptId &&
      !!updateData.receiptDate;

    if (!checkData) {
      setLoading(false);
      window.cerp.toast.warn('필수 입력값을 입력해주세요.');
      return;
    }

    try {
      const data = await Claim.updateClaim(updateData, filesList);
      if (data) {
        setLoading(false);
        window.cerp.toast.info('수정이 완료 되었습니다.');
        history.push('/compensation/accident/list');
        sessionStorage.removeItem('images');
      }
    } catch (error) {
      console.log(error);
      window.cerp.toast.error(error?.message);
      setLoading(false);
    }
  };

  const deleteClaim = async (id) => {
    try {
      const data = await Claim.deleteClaim(id);
      // console.log(data);
      if (data) {
        window.cerp.toast.info('삭제가 완료 되었습니다.');
        history.push('/compensation/accident/list');
      }
    } catch (error) {
      console.log(error);
      window.cerp.toast.error(error.response?.data?.error?.message);
    }
  };

  useEffect(() => {
    if (!!customerInfo && customerInfo.dataId) {
      getDetailData(customerInfo.dataId);
    }
  }, [customerInfo, customerInfo.dataId, getDetailData]);

  const NEW = !customerInfo.dataId;

  const handleInput = (event) => {
    const target = event.target;
    const { name, value } = target;
    setCustomerInfo({ ...customerInfo, [name]: value });
  };

  const checkWarranty = async (carNum, VIN) => {
    setLoading(true);
    try {
      const data = await Claim.getCheckWarranty(carNum, VIN);
      const vehicleInformation = JSON.parse(
        data.performanceCheckData.vehicleInformation
      );
      if (data) {
        setLoading(false);
        setCarDetailData(data);
        setCustomerInfo({
          ...customerInfo,
          carVIN: vehicleInformation.VIN + vehicleInformation.vinRemain,
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      window.cerp.toast.warn(error.response.data.error?.message);
    }
  };

  useEffect(() => {
    if (customerInfo && customerInfo.dataId) {
      checkWarranty(customerInfo.carNbr);
    } else if (customerInfo.carNum) {
      checkWarranty(customerInfo.carNum);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerInfo.dataId, customerInfo.carNum]);

  const [consultationList, setConsultationList] = useState([]);

  const getList = async (id) => {
    try {
      const data = await Claim.getConsultation({
        page: 1,
        size: 10,
        consultationGroupId: id,
      });
      setConsultationList(data.list);
    } catch (error) {
      console.log(error);
    }
  };

  const registerConsultation = async (text) => {
    const id = detailData?.consultationGroupId
      ? detailData?.consultationGroupId
      : `CON_${carDetailData?.performanceCheckData.statementNumber}`;
    try {
      const data = await Claim.posetConsultation({
        consultationGroupId: id,
        consultationDetails: text,
      });
      if (data) {
        getList(id);
      }
    } catch (error) {
      console.log(error);
      window.cerp.toast.warn(error.response.data.error?.message);
    }
  };

  const changeConsultation = async (dataId, text, useYN) => {
    setLoading(true);
    try {
      const data = await Claim.updateConsultation({
        dataId: dataId,
        consultationDetails: text,
        useYN: useYN,
      });
      if (data) {
        if (useYN === 'Y') {
          window.cerp.toast.info('수정이 완료 되었습니다.');
        } else {
          window.cerp.toast.info('삭제가 완료 되었습니다.');
        }
        getList(`CON_${carDetailData?.performanceCheckData.statementNumber}`);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!!detailData?.consultationGroupId) {
      getList(detailData?.consultationGroupId);
    } else if (
      !detailData?.consultationGroupId &&
      !!carDetailData?.performanceCheckData
    ) {
      getList(`CON_${carDetailData?.performanceCheckData.statementNumber}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailData?.consultationGroupId, carDetailData?.performanceCheckData]);

  // const scrollToDiv = (e) => {
  //   console.log(e);
  //   scrollToRef.current.scrollIntoView({ behavior: 'smooth' });
  // };

  async function getEncodedImgUrl(imageUrl) {
    const url = _.startsWith(imageUrl, 'http')
      ? imageUrl
      : process.env.REACT_APP_S3_BASE_URL + imageUrl;
    const { data: encodedUrl } = await Claim.getBase64Url(url);
    return encodedUrl;
  }

  const saveFilesAsZip = () => {
    window.cerp.toast.info('다운로드를 시작합니다.');
    const folderName = carDetailData.performanceCheckData.VHRNO;
    const files = fileItems;
    if (!files.length) return window.cerp.toast.warn('서류가 없습니다.');
    const zip = new JSZip();
    const imagesFolder = zip.folder(folderName);
    const remoteZips = files.map(async (file) => {
      const fetchedFile = await getEncodedImgUrl(file.filePath);
      return fetchedFile;
    });
    remoteZips.forEach((imgBlob, index) => {
      imagesFolder.file(`img_${index + 1}.jpg`, imgBlob, {
        blob: true,
        base64: true,
      });
    });
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, `${folderName}.zip`);
    });
    window.cerp.toast.info('다운로드가 완료되었습니다.');
  };

  const [showSmsMoal, setShowSmsModal] = useState(false);
  const [showSmsType, setShowSmsType] = useState('');
  const [smsData, setSmsData] = useState({
    msgType: '',
    sourceAddr: '010-3118-6337',
    destAddr: '',
    subject: null, //opt,
    contents: '',
    fileData: null, //opt
    url: null, // opt
  });

  const postSms = async (title) => {
    setLoading(true);
    const type = smsData.contents.length > 80 ? 'lms' : 'sms';

    const noUrl =
      title === '성능접수 안내' ||
      title === '보증 거절 안내' ||
      title === '진단점 정보 안내';

    const postData = {
      msgType: type,
      sourceAddr: smsData.sourceAddr,
      destAddr: smsData.destAddr.replaceAll('-', ''),
      subject: title,
      contents: smsData.contents,
      fileData: smsData.fileData,
      url: noUrl ? null : smsData.url,
    };
    // console.log(postData);
    try {
      const data = await Claim.postSMS(postData);
      if (data) {
        window.cerp.toast.info('메세지 전송이 완료되었습니다.');
        setShowSmsModal(false);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      window.cerp.toast.warn(error.response?.data.error?.message);
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="px-3 py-2 fixed surface-200 w-full z-5 border-bottom-1 border-400">
        <div className="flex justify-content-between">
          <div className="flex align-items-center gap-2">
            <div className="font-bold ml-2 text-xl">클레임 관리</div>
          </div>
          <div className="flex gap-4">
            {partner ? null : (
              <div className="flex gap-2">
                <div className="flex align-items-center">*업무 진행상태 :</div>
                <Dropdown
                  value={statusCode}
                  onChange={(e) => setStatusCode(e.target.value)}
                  options={statusList}
                  optionLabel="label"
                  placeholder="상태 선택"
                  className="w-full md:w-16rem font-bold"
                />
              </div>
            )}
            <Button.Default
              className="text-lg"
              icon="pi pi-save"
              label={NEW ? '클레임 저장' : '클레임 수정'}
              onClick={() => (NEW ? registerClaim() : changetClaim())}
            />
            <Button.Default
              className="p-button-outlined p-button-danger"
              icon="pi pi-times"
              label=""
              onClick={() => {
                sessionStorage.removeItem('images');
                sessionStorage.removeItem('data');
                history.push('/compensation/accident/list');
              }}
            />
          </div>
        </div>
      </div>
      <BlockUI
        fullScreen
        template={
          loading && (
            <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
          )
        }
        blocked={loading}
      >
        <div className="p-3 pt-8 gap-2 h-screen" id="claim-dialog">
          <div className="grid pb-3">
            <div className={partner ? 'col-12' : 'col-8'}>
              <Panel header="1. 접수 내역">
                <div className="grid">
                  <div className="col-6">
                    <Card title="기본 정보" style={{ height: '246px' }}>
                      <div className="field grid align-items-center">
                        <div className="col-3">
                          <label className="font-bold" htmlFor="">
                            고객명
                          </label>
                        </div>
                        <div className="col">
                          <InputText
                            readOnly={!NEW || partner}
                            value={
                              NEW
                                ? customerInfo?.customerName
                                : detailData?.customerName
                            }
                            onChange={(e) => handleInput(e)}
                            name="customerName"
                            className=" w-full"
                            placeholder="고객명 입력"
                            defaultValue=""
                          />
                        </div>
                      </div>
                      <div className="field grid align-items-center">
                        <div className="col-3">
                          <label className="font-bold" htmlFor="">
                            고객연락처
                          </label>
                        </div>
                        <div className="col">
                          <InputText
                            readOnly={!NEW}
                            value={
                              NEW
                                ? CommonUtil.Formatter.phone(
                                    customerInfo?.customerContact
                                  )
                                : CommonUtil.Formatter.phone(
                                    detailData?.customerContact
                                  )
                            }
                            onChange={(e) => handleInput(e)}
                            name="customerContact"
                            className=" w-full"
                            placeholder="연락처 입력"
                            defaultValue=""
                          />
                        </div>
                      </div>
                      <div className="field grid align-items-center">
                        <div className="col-3">
                          <label className="font-bold" htmlFor="">
                            접수일자
                          </label>
                        </div>
                        <div className="col">
                          <InputText
                            readOnly={!NEW}
                            value={
                              NEW
                                ? dayjs(today).format('YYYY-MM-DD')
                                : dayjs(detailData?.receiptDate).format(
                                    'YYYY-MM-DD'
                                  )
                            }
                            onChange={(e) => handleInput(e)}
                            name=""
                            className=" w-full"
                            defaultValue=""
                          />
                        </div>
                      </div>
                      <div className="field grid align-items-center">
                        <div className="col-3">
                          <label className="font-bold" htmlFor="">
                            접수방법
                          </label>
                        </div>
                        <div className="col">
                          <InputText
                            readOnly={!NEW}
                            value={
                              customerInfo.funnelsType === 'AC'
                                ? '앱 / 구매자'
                                : customerInfo.funnelsType === 'AS'
                                ? '앱 / 판매자'
                                : customerInfo.funnelsType === 'AE'
                                ? '앱 / 기타'
                                : customerInfo.funnelsType === 'WC'
                                ? '전화 / 구매자'
                                : customerInfo.funnelsType === 'WS'
                                ? '웹 / 판매자'
                                : '웹 / 기타'
                            }
                            onChange={(e) => handleInput(e)}
                            name=""
                            className=" w-full"
                            defaultValue=""
                          />
                        </div>
                      </div>
                    </Card>
                  </div>
                  <div className="col-6">
                    <Card title="차량 정보">
                      <div className="field grid align-items-center">
                        <div className="col-3">
                          <label className="font-bold" htmlFor="">
                            차량번호
                          </label>
                        </div>
                        <div className="col">
                          <InputText
                            readOnly
                            value={
                              NEW ? customerInfo?.carNum : detailData?.carNbr
                            }
                            onChange={(e) => handleInput(e)}
                            name="carNum"
                            className=" w-full font-bold"
                            defaultValue=""
                          />
                        </div>
                      </div>
                      <div className="field grid align-items-center">
                        <div className="col-3">
                          <label className="font-bold" htmlFor="">
                            차대번호
                          </label>
                        </div>
                        <div className="col">
                          <InputText
                            readOnly
                            value={customerInfo?.carVIN}
                            onChange={(e) => handleInput(e)}
                            name="carVIN"
                            className=" w-full font-bold"
                            defaultValue=""
                          />
                        </div>
                      </div>
                      <div className="field grid">
                        <div className="col-3 font-bold">보증가능</div>
                        <div className="col">
                          <RadioButton
                            inputId="radio_dealerName"
                            name="customerSearchBy"
                            value="1"
                            className="mr-2"
                            checked={
                              NEW
                                ? customerInfo?.association?.value ===
                                  'association'
                                : true
                            }
                          />
                          <label htmlFor="radio_dealerName">
                            협회 진단차량
                          </label>
                          <RadioButton
                            inputId="radio_dealerName"
                            name="customerSearchBy"
                            value="1"
                            className="ml-4 mr-2"
                            checked={
                              NEW
                                ? customerInfo?.association?.value ===
                                  'non_association'
                                : false
                            }
                          />
                          <label htmlFor="radio_dealerName">미 진단차량</label>
                        </div>
                      </div>
                      <div className="field grid ">
                        <div className="col-3 font-bold">보증구분</div>
                        <div className="col">
                          <RadioButton
                            inputId="radio_dealerName"
                            name="customerSearchBy"
                            value="1"
                            className="mr-2"
                            checked={
                              NEW
                                ? customerInfo?.warranty?.value === 'warranty'
                                : true
                            }
                          />
                          <label htmlFor="radio_dealerName">보험사 보증</label>
                          <RadioButton
                            inputId="radio_dealerName"
                            name="customerSearchBy"
                            value="1"
                            className="mr-2 ml-4 "
                            checked={
                              NEW
                                ? customerInfo?.warranty?.value === 'warranty_s'
                                : false
                            }
                          />
                          <label htmlFor="radio_dealerName">보험사비보증</label>
                        </div>
                      </div>
                      <div className="field grid mb-0 align-items-center">
                        <div className="col-3">
                          <label className="font-bold" htmlFor="">
                            차량위치
                          </label>
                        </div>
                        <div className="col">
                          <InputText
                            readOnly={partner}
                            value={carAddress}
                            onChange={(e) => setCarAddress(e.target.value)}
                            className=" w-full"
                            placeholder="고객 차량위치 입력"
                            defaultValue=""
                          />
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              </Panel>
              {partner ? null : (
                <>
                  <CustomerDocument
                    deleteImgs={deleteImgs}
                    setDeleteImgs={setDeleteImgs}
                    fileItems={fileItems}
                    setFileItems={setFileItems}
                    copyFile={copyFile}
                    setShowSmsType={setShowSmsType}
                    setShowSmsModal={setShowSmsModal}
                    setSmsData={setSmsData}
                    smsData={smsData}
                    carDetailData={carDetailData}
                  />
                  <WarrantyDiagnosis
                    NEW={NEW}
                    detailData={detailData}
                    customerInfo={customerInfo}
                    carInfo={carDetailData}
                    setWarrantyData={setWarrantyData}
                    warrantyData={warrantyData}
                    partnerList={partnerList}
                    setShowSmsType={setShowSmsType}
                    setShowSmsModal={setShowSmsModal}
                    setSmsData={setSmsData}
                    smsData={smsData}
                  />
                </>
              )}

              <PartnerDocument
                deleteImgs={deleteImgs}
                setDeleteImgs={setDeleteImgs}
                fileItems={fileItems}
                setFileItems={setFileItems}
                copyFile={copyFile}
                carDetailData={carDetailData}
              />

              {partner ? null : (
                <>
                  <Repair
                    repairData={repairData}
                    setRepairData={setRepairData}
                    detailData={detailData}
                    setShowSmsType={setShowSmsType}
                    setShowSmsModal={setShowSmsModal}
                  />
                  <Panel header="6. 보험료 지급" className="pt-3">
                    <div className="grid">
                      <div className="col-12">
                        <Card>
                          <div className="field grid align-items-center">
                            <div className="col-2 font-bold">
                              <label htmlFor="">보험사</label>
                            </div>
                            <div className="col-3">
                              <Dropdown
                                value={
                                  insuranceInfo.code
                                    ? insuranceInfo.code
                                    : paymentInfo?.ICNY_CODE
                                }
                                onChange={(e) =>
                                  setInsuranceInfo({
                                    ...insuranceInfo,
                                    code: e.value,
                                  })
                                }
                                options={insuranceList}
                                optionLabel="label"
                                placeholder="보험사 선택"
                                className="w-full"
                              />
                            </div>
                          </div>

                          <div className="field grid align-items-center">
                            <div className="col-2 font-bold">
                              <label htmlFor="">증권번호</label>
                            </div>
                            <div className="col-3">
                              <InputText
                                value={insuranceInfo?.insurancePolicyNumber}
                                onChange={(e) =>
                                  setInsuranceInfo({
                                    ...insuranceInfo,
                                    insurancePolicyNumber: e.target.value,
                                  })
                                }
                                className=" w-full"
                                defaultValue=""
                                placeholder="증권번호 입력"
                              />
                            </div>
                          </div>

                          <div className="field grid align-items-center">
                            <div className="col-2 font-bold">
                              <label htmlFor="">보험료 지급일</label>
                            </div>
                            <div className="col">
                              <Calendar
                                value={insuranceInfo.payDate}
                                onChange={(e) =>
                                  setInsuranceInfo({
                                    ...insuranceInfo,
                                    payDate: e.target.value,
                                  })
                                }
                                dateFormat="yy-mm-dd"
                                showButtonBar
                                showIcon
                                mask="9999-99-99"
                                placeholder="지급일 선택"
                              />
                            </div>
                          </div>
                          <div className="field grid align-items-center">
                            <div className="col-2 font-bold">
                              <label htmlFor="">보험료 총액</label>
                            </div>
                            <div className="col-3">
                              <InputText
                                value={insuranceInfo.paymentAmt}
                                onChange={(e) =>
                                  setInsuranceInfo({
                                    ...insuranceInfo,
                                    paymentAmt: e.target.value,
                                  })
                                }
                                className=" w-full"
                                placeholder="보험료 총액 입력"
                                defaultValue=""
                              />
                            </div>
                          </div>
                          <div className="field grid align-items-center">
                            <div className="col-2 font-bold">
                              <label htmlFor="">자기부담금</label>
                            </div>
                            <div className="col-3">
                              <InputText
                                value={insuranceInfo.deductibleAmt}
                                onChange={(e) =>
                                  setInsuranceInfo({
                                    ...insuranceInfo,
                                    deductibleAmt: e.target.value,
                                  })
                                }
                                className=" w-full"
                                placeholder="자기부담금 입력"
                                defaultValue=""
                              />
                            </div>
                          </div>
                          <div className="field grid align-items-center">
                            <div className="col-2 font-bold">
                              <label htmlFor="">손해액</label>
                            </div>
                            <div className="col-3">
                              <InputText
                                value={repairData.workFee}
                                // onChange={(e) => setValue(e.target.value)}
                                className=" w-full"
                                placeholder="협의비용 자동입력"
                                defaultValue=""
                              />
                            </div>
                          </div>
                          <div className="field grid align-items-center">
                            <div className="col-2 font-bold">
                              <label htmlFor="">연락처</label>
                            </div>
                            <div className="col-3">
                              <InputText
                                readOnly
                                value={myInfo.contents.contact}
                                // onChange={(e) => setValue(e.target.value)}
                                className=" w-full"
                                defaultValue=""
                              />
                            </div>
                          </div>
                          <div className="field grid align-items-center">
                            <div className="col-2 font-bold">
                              <label htmlFor="">작성자</label>
                            </div>
                            <div className="col-3">
                              <InputText
                                readOnly
                                value={myInfo.contents.userName}
                                // onChange={(e) => setValue(e.target.value)}
                                className=" w-full"
                                defaultValue=""
                              />
                            </div>
                          </div>
                          <Divider />
                          <div className="field grid align-items-center">
                            <div className="col-2 font-bold">
                              <label htmlFor="">완료일</label>
                            </div>
                            <div className="col-3">
                              <Calendar
                                value={insuranceInfo.completeDate}
                                onChange={(e) =>
                                  setInsuranceInfo({
                                    ...insuranceInfo,
                                    completeDate: e.target.value,
                                  })
                                }
                                dateFormat="yy-mm-dd"
                                showButtonBar
                                showIcon
                                mask="9999-99-99"
                                placeholder="완료일 선택"
                              />
                            </div>
                          </div>
                        </Card>
                      </div>
                    </div>
                  </Panel>
                  <div className="flex justify-content-end pt-4	pb-2">
                    {/* <Button.Default
                      className="p-button-danger"
                      icon="pi pi-trash"
                      label="클레임 삭제"
                      onClick={() => setShowModal(true)}
                    /> */}
                    <Button.Default
                      className=""
                      icon="pi pi-download"
                      label="이미지 전체 다운로드"
                      onClick={() => saveFilesAsZip()}
                    />
                  </div>
                </>
              )}
            </div>
            {partner ? null : (
              <div className="col-4 fixed right-0 z-5 top-10">
                <Counseling
                  NEW={NEW}
                  consultationList={consultationList}
                  registerConsultation={registerConsultation}
                  changeConsultation={changeConsultation}
                />
              </div>
            )}
          </div>
        </div>
      </BlockUI>

      {showModal && (
        <Modal.Default
          onHide={() => setShowModal(!showModal)}
          header={'클레임 삭제'}
          widthClass="30rem"
        >
          <div className="pb-4">클레임을 정말 삭제하시겠습니까?</div>
          <div className="flex gap-4 justify-content-center	">
            <Button.Default
              // icon="pi pi-send"
              label="삭제"
              className="p-button-outlined p-button-danger"
              onClick={() => {
                deleteClaim(detailData.dataId);
                setShowModal(false);
              }}
            />
            <Button.Default
              // icon="pi pi-send"
              label="취소"
              className="p-button-outlined"
              onClick={() => setShowModal(false)}
            />
          </div>
        </Modal.Default>
      )}

      {showSmsMoal && showSmsType && (
        <SendSMS
          showSmsType={showSmsType}
          smsData={smsData}
          setSmsData={setSmsData}
          customerInfo={customerInfo}
          onHide={() => setShowSmsModal(!showSmsMoal)}
          warrantyData={warrantyData}
          postSms={postSms}
        />
      )}
    </>
  );
}

export default ClaimDialog;
